import * as React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigation } from "@react-navigation/native";
import { theme } from '../../../Global/Theme';
import { View } from 'react-native';
import styled from '@emotion/styled';
import SignUpScreens from '../../../Screens/SignInScreens/SignInScreens';
import { AuthContext } from '../../../Context/AuthContext/AuthContext';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: '5px',
    minWidth: 180,
    color:
      'white',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },    
  },
}));

export default function MenuMobile(props) {
    const { state } = React.useContext(AuthContext);
    const navigation = useNavigation<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHome = () => {
      navigation.navigate('Home')
      handleClose(); 
    };
    const handleRemates = () => {
      navigation.navigate('Remates')
      handleClose();
    };
    const handleCadastro = () => {
      navigation.navigate(state.loggedIn ? 'Detalhes' : 'Cadastrar')
      handleClose();
    };
    const handleQuemSomos = () => {
      navigation.navigate('QuemSomos')
      handleClose();
    };
    const handleParceiros = () => {
      navigation.navigate('Parceiros')
      handleClose();
    };
    const handleContato = () => {
      navigation.navigate('FaleConosco')
      handleClose();
    };   

    return (
    <View>
       <IconButton               
        sx={{ 
          color: props.tag !== "principal" ? ('white') : (theme.colors.primary), 
          fontSize: 'large',           
          width: '50px',                    
        }}        
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <>
          <MenuItem onClick={() => handleHome()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              HOME
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleRemates()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              LEILÕES
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleCadastro()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              CADASTRO
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleQuemSomos()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              EMPRESA
            </Typography>          
          </MenuItem>          
          <MenuItem onClick={() => handleParceiros()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              PARCEIROS
            </Typography>          
          </MenuItem>          
          <MenuItem onClick={() => handleContato()}>
            <Typography translate='no' style={{ color: theme.colors.primary, marginLeft: 10 }}>
              CONTATO
            </Typography>          
          </MenuItem>                      
          <SignUpScreens isMobile={true}></SignUpScreens>          
        </>        
      </StyledMenu>
    </View>
  );
}
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigation } from "@react-navigation/native";
import { theme } from "../../../Global/Theme";
import MenuMobile from '../MenuMobile/MenuMobile';
import SignUpScreens from '../../../Screens/SignInScreens/SignInScreens';
import { AuthContext } from '../../../Context/AuthContext/AuthContext';
import { useContext } from 'react';

const ImageButton = styled('span')({
  position: 'relative',
  backgroundColor: theme.colors.secondary,  
  color: theme.colors.primary,    
  height: '25px',  
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
      backgroundColor: theme.colors.primary,  
      color: theme.colors.secondary,
      borderRadius: '20px',
    },
    '& .MuiImageMarked-root': {
      opacity: 0,      
      backgroundColor: theme.colors.primary,  
      color: theme.colors.secondary,
      borderRadius: '20px',
    },
    '& .MuiTypography-root': {
      border: '0px solid currentColor',      
      backgroundColor: theme.colors.primary,  
      color: theme.colors.secondary,
      borderRadius: '20px',    
    },
  },
});

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 5,
  bottom: 0,
  backgroundSize: '90px 50px',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.primary,
});

export default function ButtonBasesPrincipal(tag) {
  const { setState, state } = useContext(AuthContext);
  const { innerWidth: width, innerHeight: height } = window;
  const navigation = useNavigation<any>();
  const handleQuemSomos = () => {
    navigation.navigate("QuemSomos")
  };

  const handleParceiros = () => {
    navigation.navigate("Parceiros")
  };
  
  const fontAdapt = innerWidth <  640 ? '9px' : '13px';
  
  return (
    <>
      {innerWidth > 640 ? (
        <>
          <ImageButton
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 15,
            }}
          >
            <ImageSrc 
            >        
            <Image onClick={() => navigation.navigate("Inicio")}>
              <Typography
                component="span"
                fontSize={fontAdapt}
                variant="overline"
                color="inherit"
                sx={{
                  fontWeight: 'bold',                   
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                INICIO
              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>

          <ImageButton
            key={tag}        
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
            >        
            <Image onClick={() => navigation.navigate('Remates')}>
              <Typography
                component="span"
                fontSize={fontAdapt}
                variant="overline"
                color="inherit"
                sx={{
                  fontWeight: 'bold',                   
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                LEILÕES

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>
          
          <ImageButton
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
            >        
            <Image onClick={handleQuemSomos}>
              <Typography
                component="span"
                variant="overline"
                fontSize={fontAdapt}
                color="inherit"
                sx={{
                  fontWeight: 'bold',                   
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                EMPRESA

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>

          <ImageButton
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
            >    
            <Image onClick={() => navigation.navigate(state.loggedIn ? "Detalhes" : "Cadastrar")}>
              <Typography
                component="span"
                variant="overline"
                fontSize={fontAdapt}
                color="inherit"
                sx={{
                  fontWeight: 'bold',                   
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                CADASTRO

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>

          <ImageButton
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
            >        
            <Image onClick={handleParceiros}>
              <Typography
                component="span"
                variant="overline"
                fontSize={fontAdapt}
                color="inherit"
                sx={{
                  fontWeight: 'bold',                   
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                PARCEIROS

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>
         

          <ImageButton
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
            >        
            <Image onClick={() => navigation.navigate("FaleConosco")}>
              <Typography
                component="span"
                fontSize={fontAdapt}
                variant="overline"
                color="inherit"
                sx={{
                  fontWeight: 'bold',                   
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                CONTATO

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>
          <SignUpScreens isMobile={false}></SignUpScreens>          
      </>
    ) : 
    (<> 
    <MenuMobile tag="principal"></MenuMobile>
    </>)}
    </>
  )
}

import { Appbar } from "react-native-paper";
import { theme } from "../../../Global/Theme";

import ButtonBasesPrincipal from "../../Cards/ButtomMenu/ButtomMenuPrincipal";
import { Table, Typography } from "@mui/material";
import { View } from "react-native";
import { useContext } from "react";
import { AuthContext } from "../../../Context/AuthContext/AuthContext";

const urlLogo = 'https://i.ibb.co/NsPhG1W/logo-macedo.png';
const imagemTop = 'https://i.ibb.co/4NX2MW1/barra-superior.png';

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  nome: string;
  cpfcgc: string;
  endereco: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
}

const MenuPrincipal = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const { setState, state } = useContext(AuthContext);
  const isMobile = () => {    
    if (innerWidth <  640) {
      return true
    } else {
      return false
    }
  };

  return (
    <View style={{ backgroundColor: theme.colors.secondary }}>
      <div style={{ 
        backgroundImage: `url(${imagemTop})`,
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '30px',
      }}/>
      {state.loggedIn ? 
        <Typography style={{ marginRight: 10, textAlign: 'right', fontWeight: 'bold' }} fontSize={12}>Logado por : {state.user.nomexx}</Typography> : null
      }    
      
      < Appbar.Header
        mode="center-aligned"
        style={{
          backgroundColor: theme.colors.secondary,
          height: '35px',
          width: '100%',          
          justifyContent: "flex-end",
          alignItems: "flex-end"
        }}
      >
      <ButtonBasesPrincipal tag={location}></ButtonBasesPrincipal>        
      </Appbar.Header >

      <div style={{ width: '100%', 
                    height: '120px',
                    display: 'flex' 
                  }}
      >        
        <div style={{
          backgroundImage: `url('https://i.ibb.co/0qKVGL0/barra-superior-2.png')`,  
          backgroundRepeat: 'repeat-x',  
          marginTop: '30px',  
          marginBottom: '50px',  
          width: '100%',  
          height: '70px'}}
        >            
          <Table style={{ marginTop: '-25px', marginLeft: '10px' }}>              
          <tbody>
          <tr>                
          <td style={{ width: '100px'}}>                    
            <div 
              style={{ 
                marginLeft: isMobile() ? 5 : 20,
                width: '100px', 
                height: '100px', 
                background: `url(${urlLogo})`,
                backgroundRepeat: 'repeat-x',
                backgroundSize: '100px 100px',
                border: 0
               }}             
              />                                        
          </td>        
          <td>
          <Typography 
            style={{ 
              marginLeft: 20,
              marginTop: -10,
              fontFamily: 'Lato, sans-serif', 
              color: 'white', 
              fontWeight: 'bold',
              fontSize: innerWidth < 640 ? '14px': '18px'}}>
                LUGAR DE BONS NEGOCIOS
          </Typography>                
          </td>                          
          </tr>
          </tbody>
          </Table>        
        </div>
      </div>             
    </View>
  )
}

export default function Menu() {  
  return (
    <MenuPrincipal/>
  )
}


import React from "react";
import { NavigationContainer, getPathFromState } from '@react-navigation/native';
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import HomeScreen from "../Screens/HomeScreen/HomeScreen";
import ProductsScreen from "../Screens/ProductsScreen/ProductsScreen";
import SignInScreens from "../Screens/SignInScreens/SignInScreens";
import SignUpScreens from "../Screens/SignUpScreens/SignUpScreens";
import ContactScreen from "../Screens/ContactScreen/ContactScreen";
import { Leilao } from "../utils/types/Leilao.type";
import SaleScreen from "../Screens/SaleScreen/SaleScreen";

import HistoryScreen from "../Screens/HistoryScreen/HistoryScreen";
import CheckDocument from "../Screens/RecoveryPassword/CheckDocument/CheckDocument";
import ChangePassword from "../Screens/RecoveryPassword/ChangePassword/ChangePassword";
import PartnersScreen from "../Screens/PartnersScreen/PartnersScreen";
import PrivacyScreen from "../Screens/PrivacyScreen/PrivacyScreen";
import DetailsScreen from "../Screens/DetailsScreen/DetailsScreen";
import AppsScreen from "../Screens/AppsScreen/AppsScreen";

export type ProductsStackParamList = {
  ProductsScreen: undefined;
  SaleScreen: { leilao: Leilao };
};

const ProductsStack = createNativeStackNavigator<ProductsStackParamList>();
function ProductsRootStack() {
  return (
    <ProductsStack.Navigator
      screenOptions={() => ({
        headerShown: false,
      })}
    >
      <ProductsStack.Screen name="ProductsScreen" component={ProductsScreen} />
      <ProductsStack.Screen name="SaleScreen" component={SaleScreen} />
    </ProductsStack.Navigator>
  );
}

const StackAuthNavigator = createNativeStackNavigator();
function AuthNavigator() {
  return (
    <StackAuthNavigator.Navigator initialRouteName="SignInScreens">
      <StackAuthNavigator.Screen

        name="SignInScreens"
        component={SignInScreens}
        
        options={{ headerShown: false }}
      />
      <StackAuthNavigator.Screen
        name="CheckDocument"
        component={CheckDocument}
        options={{ headerShown: false }}
      />
      <StackAuthNavigator.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={{ headerShown: false }}
      />
    </StackAuthNavigator.Navigator>
  );
}


const linking = {
  prefixes: ['http://localhost:8080', 'http://master.d1pltv27f5nzsz.amplifyapp.com'],
  config: {
    screens: {
      Inicio: '',
      Remates: '/leiloes',
      Cadastrar: '/cadastro',
      QuemSomos: '/empresa',
      Parceiros: '/parceiros',
      FaleConosco: '/faleconosco',
      Login: '/login',
      Privacidade: '/privacidade',
      SaleScreen: '/leilao/:leilao',
      Aplicativos: '/aplicativos',
    }
  },
  getPathFromState: (state, options) => {
    const cleanState = {
      ...state,
      routes: state.routes.map(route => {
        if(!route.params) {
          return route
        }

        const cleanParams = {}
        for(const param in route.params) {
          const value = route.params.leilao.ID
          if(typeof value !== "object" && typeof value !== "function") {
            cleanParams[param] = value
          }
        }
        return {
          ...route,
          params: cleanParams,
        }
      }),
    }
    return getPathFromState(cleanState, options) //imported from @react-navigation/native
  },
};


const Drawer = createNativeStackNavigator();
export default function Routes() {
  return (
    <NavigationContainer linking={linking} independent={true}>      
    <Drawer.Navigator                
      screenOptions={{
        headerShown: false
      }}
    >
      <Drawer.Screen
        name="Inicio"
        component={HomeScreen}
      />
      <Drawer.Screen
        name="Home"
        component={HomeScreen}
      />
      <Drawer.Screen
        name="Remates"
        component={ProductsScreen}
      />
      <Drawer.Screen
        name="Cadastrar"
        component={SignUpScreens}
      />
      <Drawer.Screen
        name="Detalhes"
        component={DetailsScreen}
      />
      <Drawer.Screen
        name="QuemSomos"
        component={HistoryScreen}
      />
      <Drawer.Screen
        name="Parceiros"
        component={PartnersScreen}
      />
      <Drawer.Screen
        name="FaleConosco"
        component={ContactScreen}
      />
      <Drawer.Screen
        name="Privacidade"
        component={PrivacyScreen}
      />
      <Drawer.Screen
        name="Login"
        component={AuthNavigator}
      />
      <Drawer.Screen
        name="SaleScreen"
        component={SaleScreen}
      />
       <Drawer.Screen
        name="Aplicativos"
        component={AppsScreen}
      />
    </Drawer.Navigator >
    </NavigationContainer>
  );
}

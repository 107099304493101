import { Image } from "react-native";
import React from "react";
import { Card } from "react-native-paper";
import { Leilao } from "../../../utils/types/Leilao.type";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";
import { theme } from "../../../Global/Theme";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import useMixpanel from "../../../hooks/useMixpanel";
type IProps = {
  leilao: Leilao;
  route: any;
};


export type RootStackParamList = {
  SaleScreen: { leilao: Leilao } | undefined;
};

export default function CardLeilao({ leilao, route }: IProps) {    
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { trackEvent } = useMixpanel('d70609b52efef69ee6459b93c344f0c9');
  const handlePress = () => {
    trackEvent('Acessando Leilão ' + leilao.ID, { Screen: 'SalesScreen' });
    navigation.navigate("SaleScreen", {
      leilao,
    })
  };

  const larguraImagem = () => {
    if (route === 'home') {
      return innerWidth < 600 ? innerWidth - 50 : 1200;
    } else {
      return 360;
    }
  }

  const alturaImagem = () => {
      if (route === 'home') {  
        return innerWidth < 600 ? innerWidth - 50 : 400;              
      } else
      {
        return 360
      }                
  }

  const nameImage = () => {
    if (route === 'home' && innerWidth < 640) {
      return 'mobile'
    }
    else if (route === 'home' && innerWidth > 640) {
      return 'desktop'
    }
    else {
      return 'mobile'
    }
  }

  return (
    <Card
      onPress={handlePress}
      theme={{ roundness: 0 }}
      style={{
        marginVertical: 0,
        borderRadius: 12,
        backgroundColor: theme.colors.secondary,
        marginLeft: route === 'products' ? 5 : 0,
        width: larguraImagem(),
        height: alturaImagem(),
      }}
    >      
      <Image
        style={{          
          width: larguraImagem(),
          height: alturaImagem(),
          borderRadius: 12,
          marginLeft: route === 'products' ? 5 : 0,
          marginTop: route === 'products' ? 5 : 0, 
        }}
        source={{ 
          uri: "https://macedo.s3.us-east-2.amazonaws.com/" + nameImage() + "_leilao_img_" + leilao.ID + ".png" ,          
          cache:'reload',          
         }}
        resizeMode="contain"
        
      />
      <Card.Actions style={{ justifyContent: "flex-start" }}>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("SaleScreen", {
              leilao,
            })
          }
        >          
        </TouchableOpacity>
      </Card.Actions >
    </Card >
  );
}
